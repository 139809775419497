<script setup lang="ts">
import { useClientStore } from "@/stores/clients";
import { useDocumentsStore } from "@/stores/documents";
import { computed, defineProps, PropType } from "vue";
import commonTools from "@/tools/common-tools";
import { projectToSlug } from "@/tools/projects";
import { Project } from "@/types";
import Ok from "@/components/general/Ok.vue";
import { useRouter } from "vue-router";
import ProjectCardDocuments from "./ProjectCardDocuments.vue";

const props = defineProps({
    project: {
        type: Object as PropType<Project>,
        required: true,
    },
});

const router = useRouter();
const clientsStore = useClientStore();
const documentsStore = useDocumentsStore();

const clientName = computed(() => {
    const client = clientsStore.getItemById(props.project.clientId);
    return client ? client.name : "";
});

const budget = computed(() => {
    const budget =
        props.project.hours * props.project.rate - props.project.discount;
    return commonTools.currencyFormat(budget);
});

const documents = computed(() => {
    return documentsStore.getDocumentsForProjectOfType(
        props.project.id,
        "invoice"
    );
});

const isPaidInvoice = computed(() => {
    if (
        props.project.projectStatus === 3 ||
        props.project.projectStatus === 4
    ) {
        let paid = true;
        for (let document of documents.value) {
            if (!document.paid) {
                paid = false;
            }
        }
        return paid;
    } else {
        return false;
    }
});

const select = () => {
    router.push({ name: "Project", params: { slug: slug.value } });
};

const slug = computed(() => {
    return projectToSlug(props.project, -1);
});

const status = computed(() => {
    return props.project.projectStatus === 2 && props.project.finished
        ? "2--ready"
        : props.project.projectStatus;
});
</script>

<template>
    <div
        :style="{ background: 'var(--status-' + status + ')' }"
        class="ProjectCardProperties"
    >
        <div class="ProjectCardProperties__name" @click="select()">
            {{ slug }}
            <ok v-if="isPaidInvoice" />
        </div>

        <div class="ProjectCardProperties__client" @click="select()">
            {{ clientName }}
        </div>

        <div class="ProjectCardProperties__documents">
            <project-card-documents :project="project" :doctype="'invoice'" />
            <project-card-documents :project="project" :doctype="'quotation'" />
        </div>

        <div class="ProjectCardProperties__budget" @click="select()">
            {{ budget }}
            {{ project.currency }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.ProjectCardProperties {
    height: 100%;
    width: calc(100% - 80px);
    display: flex;
    cursor: pointer;
    border-left: 4px solid transparent;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-left: 1px solid rgba(0, 0, 0, 0.2);

    > div {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 8px;
        border-right: 1px solid rgba(0, 0, 0, 0.2);
        white-space: nowrap;
        overflow: hidden;
    }

    &__name {
        width: 250px;
        border-left: 4px solid transparent;

        .Ok {
            margin-left: 10px;
        }
    }

    &__client {
        width: calc(100% - 600px);
    }

    &__documents {
        width: 350px;
        display: flex;
        overflow: hidden;
        gap: 6px;
    }

    &__budget {
        width: 100px;
        justify-content: flex-end;
    }
}
</style>
